<template>
  <v-container>
    <PullRefresh :on-refresh="onRefresh" class="primary container-history">
      <div class="logbook-filter fill-width" style="position: sticky;">
        <v-row no-gutters class="pa-2">
          <v-col cols="6" class="pr-1">
            <v-menu
              ref="menuDateRange"
              v-model="menuDateRange"
              :return-value.sync="dates"
              left
              offset-y
              :close-on-content-click="false"
              min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="dateRangeText"
                  placeholder="Date Ranges"
                  prepend-inner-icon="mdi-calendar-month"
                  readonly
                  hide-details
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  class="datepicker-logbook search-history rounded-pill"
                  @click:clear="dates = []" />
              </template>
              <v-date-picker v-model="dates" no-title light class="force-light-font" range>
                <v-spacer />
                <v-btn v-if="dates.length" text class="red--text text-capitalize" small @click="$refs.menuDateRange.save([])">
                  Clear
                </v-btn>
                <v-btn text class="text-capitalize" small @click="$refs.menuDateRange.save(dates)">
                  Set
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6" class="pl-1">
            <v-autocomplete
              v-model="selectedAirport"
              :items="$store.state.logbook.airports"
              :item-text="(v) => {
                return v ? (v.iata_code || '-') + ' | ' + (v.icao_code || '-') + ' - ' + v.name : ''
              }"
              item-value="id"
              placeholder="Airport"
              prepend-inner-icon="mdi-magnify"
              hide-details
              clearable
              color="primary"
              class="select-input-logbook search-history rounded-pill" />
          </v-col>
        </v-row>
      </div>
      <div class="card-container">
        <v-row class="content-container" no-gutters>
          <v-col cols="12" class="pt-0" style="margin-top: -15px;">
            <v-list class="pt-0 pb-10" style="background: transparent;">
              <div v-if="!switchNewLogbook && $store.state.logbookHistory.data.length"
                class="d-flex fill-width justify-center font-13 fw-500 error--text font-italic px-4 py-4">
                For data to be continuous and integrated, Old Forpilots Data is only calculated and displayed until the new
                schedule on the new application has been created for the first time!
              </div>
              <div v-if="loadingData" class="pa-4 text-center">
                <Loading show="true" color="primary" />
              </div>
              <v-tabs v-if="switchNewLogbook" v-model="unsignedLogbookTab" centered background-color="transparent">
                <v-tab href="#tab-all" class="disable-ripple text-capitalize">
                  <v-icon>mdi-contact</v-icon>
                  All
                </v-tab>
                <v-tab href="#tab-unsigned" class="disable-ripple text-capitalize">
                  Unsigned
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="unsignedLogbookTab">
                <v-tab-item key="tab-all" value="tab-all">
                  <template v-if="$store.state.logbookHistory.data">
                    <v-card v-for="(ls, iLs) in $store.state.logbookHistory.data" :key="'history-' + iLs"
                      class=" elevation-0 pa-0 my-4 px-2 card-outlined primary white--text" style="border-radius: 20px;"
                      @click.prevent="switchNewLogbook ? $router.push('?scheduleDate=' + $localDT(ls.duty_local_date, 'datedefault')) : $router.push('/history?dialogLogbook=' + ls.id)">
                      <v-list-item link class="text-left px-2">
                        <v-list-item-content>
                          <v-list-item-title>
                            <flex v-if="switchNewLogbook" class="flex">
                              <v-row no-gutters class="justify-space-between pt-2 pb-3">
                                <v-col cols="auto">
                                  <v-icon
                                    :color="switchNewLogbook ? (IsPassed(ls.ids, ls.ids_passed) ? 'green' : 'red') : 'white'"
                                    mid>
                                    {{ switchNewLogbook ? (IsPassed(ls.ids, ls.ids_passed) ? 'mdi-checkbox-marked-circle' : 'mdi-alert-circle') : 'mdi-weather-cloudy-clock' }}
                                  </v-icon>
                                  <span class="py-1 pl-4 white--text fw-700 font-16">
                                    {{ $localDT(ls.duty_local_date, 'daydate') }}
                                  </span>
                                </v-col>
                                <v-col cols="auto" class="mr-2">
                                  <span class="white--text fw-600 font-16" x-small>
                                    {{ parseFloat(ls.flighttime_total || 0).toFixed(1) }}
                                  </span>
                                </v-col>
                              </v-row>
                            </flex>
                            <flex v-else class="flex-space-beetween pb-1">
                              <span class="font-16 fw-600 py-1 white--text">
                                {{ $localDT(ls.duty_start, 'daydate') }}
                              </span>
                              <v-chip x-small>
                                {{ ls.flighttime }}
                              </v-chip>
                            </flex>
                          </v-list-item-title>
                          <div class="divider py-1 ma-0" />
                          <v-list-item-subtitle v-if="!switchNewLogbook" class="caption text-uppercase">
                            <div v-html="getRoutes(ls)" class="route-etd-eta white--text font-13" />
                          </v-list-item-subtitle>
                          <div v-if="switchNewLogbook" class="d-flex flex-column fill-width caption white--text">
                            <v-expansion-panels>
                              <v-expansion-panel @click.stop class="logbook-custom-panel primary">
                                <v-expansion-panel-header hide-actions>
                                  <v-row no-gutters class="white--text align-center justify-space-between">
                                    <v-col cols="1">
                                      &#8203;
                                    </v-col>
                                    <v-col cols="auto">
                                      <span>
                                        {{ ls.schedules ? BindSchedule(ls.schedules).filter(r => !r.afl_signedby_id).length ? `${BindSchedule(ls.schedules).filter(r => r.afl_signedby_id).length} / ${BindSchedule(ls.schedules).length}` : 'Completed' : 'Data Damaged' }}
                                      </span>
                                    </v-col>
                                    <v-col cols="1">
                                      <v-icon color="white" class="">
                                        $expand
                                      </v-icon>
                                    </v-col>
                                  </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                  <div v-for="(s, iS) in BindSchedule(ls.schedules)" :key="iS"
                                    class="justify-space-between align-center " style="color: white;">
                                    <div>
                                      <div class="mb-5 d-flex align-center justify-space-between">
                                        <div>
                                          <v-icon
                                            :color="parseInt(s.afl_signedby_id) ? 'green' : 'red '"
                                            class="mr-1"
                                            dark
                                            size="25px">
                                            {{ parseInt(s.afl_signedby_id) ? 'mdi-checkbox-marked-circle' : 'mdi-alert-circle' }}
                                          </v-icon>
                                        </div>
                                        <div>
                                          <div class="d-flex flex-column align-center ">
                                            <div class="divider2nd">
                                              <div class="dot" />
                                              <div class="line" />
                                              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 256 256">
                                                <g fill="white">
                                                  <path
                                                    d="M240 136v24H61.06a32 32 0 0 1-30.65-22.8L16.34 90.3A8 8 0 0 1 24 80h8l24 24h36.91L80.42 66.53A8 8 0 0 1 88 56h8l48 48h64a32 32 0 0 1 32 32"
                                                    opacity="0" />
                                                  <path
                                                    d="M224 216a8 8 0 0 1-8 8H72a8 8 0 1 1 0-16h144a8 8 0 0 1 8 8m24-80v24a8 8 0 0 1-8 8H61.07a39.75 39.75 0 0 1-38.31-28.51L8.69 92.6A16 16 0 0 1 24 72h8a8 8 0 0 1 5.65 2.34L59.32 96h22.49l-9-26.94A16 16 0 0 1 88 48h8a8 8 0 0 1 5.66 2.34L147.32 96H208a40 40 0 0 1 40 40m-16 0a24 24 0 0 0-24-24h-64a8 8 0 0 1-5.65-2.34L92.69 64H88l12.49 37.47A8 8 0 0 1 92.91 112H56a8 8 0 0 1-5.66-2.34L28.69 88H24l14.07 46.9a23.85 23.85 0 0 0 23 17.1H232Z" />
                                                </g>
                                              </svg>
                                              <div class="line" />
                                              <div class="dot filled" />
                                            </div>
                                            <div>
                                              {{ s.etd_local_time }} - {{ s.eta_local_time }} LT
                                            </div>
                                          </div>
                                        </div>
                                        <div class="d-flex flex-column align-center">
                                          <span class="d-inline-flex align-center ml-1">
                                            {{ s.dep }} - {{ s.arr }}
                                          </span>
                                          <strong>{{ s.fleet_reg }} </strong>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>
                          </div>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card>
                  </template>
                  <div v-else-if="!loadingData" class="logbook-empty">
                    <EmptyFlight />
                    <div class="mt-8">
                      Let's start your journey
                    </div>
                  </div>
                </v-tab-item>
                <v-tab-item key="tab-unsigned" value="tab-unsigned">
                  <template v-if="$store.state.logbookHistory.data.filter(r => !IsPassed(r.ids, r.ids_passed)).length">
                    <v-card v-for="(ls, iLs) in $store.state.logbookHistory.data.filter(r => !IsPassed(r.ids, r.ids_passed))" :key="'history-' + iLs"
                      class=" elevation-0 pa-0 my-4 px-2 card-outlined primary white--text" style="border-radius: 20px;"
                      @click.prevent="switchNewLogbook ? $router.push('?scheduleDate=' + $localDT(ls.duty_local_date, 'datedefault')) : $router.push('/history?dialogLogbook=' + ls.id)">
                      <v-list-item link class="text-left px-2">
                        <v-list-item-content>
                          <v-list-item-title>
                            <flex v-if="switchNewLogbook" class="flex">
                              <v-row no-gutters class="justify-space-between pt-2 pb-3">
                                <v-col cols="auto">
                                  <v-icon
                                    :color="switchNewLogbook ? (IsPassed(ls.ids, ls.ids_passed) ? 'green' : 'red') : 'white'"
                                    mid>
                                    {{ switchNewLogbook ? (IsPassed(ls.ids, ls.ids_passed) ? 'mdi-checkbox-marked-circle' : 'mdi-alert-circle') : 'mdi-weather-cloudy-clock' }}
                                  </v-icon>
                                  <span class="py-1 pl-4 white--text fw-700 font-16">
                                    {{ $localDT(ls.duty_local_date, 'daydate') }}
                                  </span>
                                </v-col>
                                <v-col cols="auto" class="mr-2">
                                  <span class="white--text fw-600 font-16" x-small>
                                    {{ parseFloat(ls.flighttime_total || 0).toFixed(1) }}
                                  </span>
                                </v-col>
                              </v-row>
                            </flex>
                            <flex v-else class="flex-space-beetween pb-1">
                              <span class="font-16 fw-600 py-1 white--text">
                                {{ $localDT(ls.duty_start, 'daydate') }}
                              </span>
                              <v-chip x-small>
                                {{ ls.flighttime }}
                              </v-chip>
                            </flex>
                          </v-list-item-title>
                          <div class="divider py-1 ma-0" />
                          <v-list-item-subtitle v-if="!switchNewLogbook" class="caption text-uppercase">
                            <div v-html="getRoutes(ls)" class="route-etd-eta white--text font-13" />
                          </v-list-item-subtitle>
                          <div v-if="switchNewLogbook" class="d-flex flex-column fill-width caption white--text">
                            <v-expansion-panels>
                              <v-expansion-panel @click.stop class="logbook-custom-panel primary">
                                <v-expansion-panel-header hide-actions>
                                  <v-row no-gutters class="white--text align-center justify-space-between">
                                    <v-col cols="1">
                                      &#8203;
                                    </v-col>
                                    <v-col cols="auto">
                                      <span>
                                        {{ ls.schedules ? BindSchedule(ls.schedules).filter(r => !r.afl_signedby_id).length > 1 ? BindSchedule(ls.schedules).filter(r => !r.afl_signedby_id).length + ' Unsigned Logbooks' : 'One Unsigned Logbook' : 'Data Damaged' }}
                                      </span>
                                    </v-col>
                                    <v-col cols="1">
                                      <v-icon color="white" class="">
                                        $expand
                                      </v-icon>
                                    </v-col>
                                  </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                  <div v-for="(s, iS) in BindSchedule(ls.schedules).filter(r => !r.afl_signedby_id)" :key="iS"
                                    class="justify-space-between align-center " style="color: white;">
                                    <div>
                                      <div class="mb-5 d-flex align-center justify-space-between">
                                        <div>
                                          <v-icon
                                            :color="parseInt(s.afl_signedby_id) ? 'green' : 'red '"
                                            class="mr-1"
                                            dark
                                            size="25px">
                                            {{ parseInt(s.afl_signedby_id) ? 'mdi-checkbox-marked-circle' : 'mdi-alert-circle' }}
                                          </v-icon>
                                        </div>
                                        <div>
                                          <div class="d-flex flex-column align-center ">
                                            <div class="divider2nd">
                                              <div class="dot" />
                                              <div class="line" />
                                              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 256 256">
                                                <g fill="white">
                                                  <path
                                                    d="M240 136v24H61.06a32 32 0 0 1-30.65-22.8L16.34 90.3A8 8 0 0 1 24 80h8l24 24h36.91L80.42 66.53A8 8 0 0 1 88 56h8l48 48h64a32 32 0 0 1 32 32"
                                                    opacity="0" />
                                                  <path
                                                    d="M224 216a8 8 0 0 1-8 8H72a8 8 0 1 1 0-16h144a8 8 0 0 1 8 8m24-80v24a8 8 0 0 1-8 8H61.07a39.75 39.75 0 0 1-38.31-28.51L8.69 92.6A16 16 0 0 1 24 72h8a8 8 0 0 1 5.65 2.34L59.32 96h22.49l-9-26.94A16 16 0 0 1 88 48h8a8 8 0 0 1 5.66 2.34L147.32 96H208a40 40 0 0 1 40 40m-16 0a24 24 0 0 0-24-24h-64a8 8 0 0 1-5.65-2.34L92.69 64H88l12.49 37.47A8 8 0 0 1 92.91 112H56a8 8 0 0 1-5.66-2.34L28.69 88H24l14.07 46.9a23.85 23.85 0 0 0 23 17.1H232Z" />
                                                </g>
                                              </svg>
                                              <div class="line" />
                                              <div class="dot filled" />
                                            </div>
                                            <div>
                                              {{ s.etd_local_time }} - {{ s.eta_local_time }} LT
                                            </div>
                                          </div>
                                        </div>
                                        <div class="d-flex flex-column align-center">
                                          <span class="d-inline-flex align-center ml-1">
                                            {{ s.dep }} - {{ s.arr }}
                                          </span>
                                          <strong>{{ s.fleet_reg }} </strong>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>
                          </div>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card>
                  </template>
                  <div v-else-if="!loadingData" class="logbook-empty">
                    <EmptyFlight />
                    <div class="mt-8">
                      All good, you did a great job
                    </div>
                  </div>
                </v-tab-item>
              </v-tabs-items>
              <div v-if="nexLoaded()" class="pt-2 pb-8">
                <v-btn small depressed @click.prevent="loadLogbookHistory(switchNewLogbook)" class="load-button rounded-lg">
                  Load more
                </v-btn>
              </div>
            </v-list>
          </v-col>
        </v-row>
      </div>
    </PullRefresh>
    <div class="switch-logbook d-flex justify-center fill-width">
      <div class="d-flex" style="width: 95%;">
        <div class="d-flex px-2" style="width: 50%;">
          <v-btn :color="switchNewLogbook ? 'secondary' : 'grey'" block dark class="font-16 text-capitalize rounded-pill"
            @click.prevent="switchNewLogbook = true">
            <v-icon class="mr-1">
              {{ switchNewLogbook ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank' }}
            </v-icon>
            New Logbook
          </v-btn>
        </div>
        <div class="d-flex px-2" style="width: 50%;">
          <v-btn :color="switchNewLogbook ? 'grey' : 'secondary'" block dark class="font-16 text-capitalize rounded-pill"
            @click.prevent="switchNewLogbook = false">
            <v-icon class="mr-1">
              {{ switchNewLogbook ? 'mdi-radiobox-blank' : 'mdi-radiobox-marked' }}
            </v-icon>
            Old Forpilots
          </v-btn>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import { getMessaging, onMessage } from 'firebase/messaging'

export default {
  name: 'History',
  data: () => ({
    switchNewLogbook: true,
    unsignedLogbookTab: null,
    menuDateRange: false,
    dates: [],
    loadingData: false,
    logbookHistory: [],
    page: 0,
    selectedAirport: null,
    flighttimeTotal: 0
  }),
  computed: {
    dateRangeText () {
      return this.dates.join(' ~ ')
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (to, from) {
        if (to.name === 'History' && !from) {
          if (to.query.scheduleDate) {
            this.$router.replace('/')
          } else {
            this.page = 0
            this.switchNewLogbook = true
            this.loadLogbookHistory(this.switchNewLogbook)
          }
        } else if (to.name === 'History') {
          this.page = 0
          if (from.query.dialogLogbook) {
            this.switchNewLogbook = false
          } else {
            this.switchNewLogbook = true
          }
          this.loadLogbookHistory(this.switchNewLogbook)
        }
      }
    },
    switchNewLogbook (v) {
      this.page = 0
      this.$store.commit('LOGBOOK_HISTORY', { total: 0, data: [] })
      this.loadLogbookHistory(v)
    },
    dates: function (dt) {
      if (dt.length !== 1) {
        this.page = 0
        this.loadLogbookHistory(this.switchNewLogbook)
      }
    },
    selectedAirport: function (v) {
      this.page = 0
      this.loadLogbookHistory(this.switchNewLogbook)
    }
  },
  created () {
    const logbookID = parseInt(this.$route.query.dialogLogbook)
    if (logbookID > -1) {
      this.$router.replace('/history')
    }
  },
  mounted () {
    // Receive Notification from backend
    onMessage(getMessaging(), (payload) => {
      this.onRefresh()
      this.$toastNotif({
        message: payload.notification.body,
        type: 'info'
      })
    })

    this.switchNewLogbook = true
    this.loadLogbookHistory(this.switchNewLogbook)
  },
  methods: {
    onRefresh () {
      const refreshData = () => {
        this.$store.dispatch('ME_GET')
        this.switchNewLogbook = true
        this.loadLogbookHistory(this.switchNewLogbook)
        if (localStorage.getItem('t') && localStorage.getItem('push_token') && !this.$store.state.admin) {
          this.$store.dispatch('CHECK_T', localStorage.getItem('push_token')).then((res) => {
            if (!res) {
              this.$toastNotif({
                message: 'Oops! Looks like your account sneaked onto another device. Please re-login to get back in action!',
                type: 'info',
                timer: 3500
              })
              this.$store.dispatch('DO_LOGOUT')
            }
          })
        }
      }

      return new Promise((resolve, reject) => {
        setTimeout(() => {
          refreshData()
          resolve()
        }, 1000)
      })
    },
    confirmedSchedules () {
      const dataArray = this.$store.state.logbookHistory.data
      // Iterate over each object in the data array
      const confirmedSchedulesArray = dataArray.map(item => {
        // Split the 'schedules' string into an array of schedule objects
        const schedulesArray = item.schedules.split(')|(')
        // Iterate over each schedule object in the schedules array
        const schedules = schedulesArray.map(schedule => {
          const fields = schedule.split('#|#')
          return {
            id: fields[0],
            afl_signedby_id: parseInt(fields[1]) || 0,
            sch_status: fields[1], // You might want to adjust this based on your actual data structure
            afl_validatedby_id: parseInt(fields[2]) || 0,
            fleet_reg: fields[3],
            pilot_name: fields[4],
            copilot_name: fields[5],
            etd_local_time: fields[6],
            eta_local_time: fields[7],
            afl_takeoff: fields[8],
            afl_landing: fields[9],
            afl_taxi_decimal: fields[10],
            dep: fields[11],
            arr: fields[12],
            dep_fullname: fields[13],
            arr_fullname: fields[14],
            notes: fields[15] || '',
            isConfirmed: parseInt(fields[1]) === 724 ? 'true' : 'false'
          }
        })
        return { ...item, schedules }
      })
      return confirmedSchedulesArray
    },
    getTotalSchStatus (schedules) {
      if (!schedules) return 0
      const schs = schedules.split(')|(')
      let totalCount = 0
      for (let s = 0; s < schs.length; s++) {
        const sch = schs[s].split('#|#')
        // Check if sch_status exists and is not empty
        if (sch[1] && sch[1].trim() !== '') {
          totalCount++
        }
      }
      return totalCount
    },
    IsPassed (ids, idsPassed) {
      return parseInt(ids) > 0 ? parseInt(idsPassed) === parseInt(ids) : false
    },
    BindSchedule (schedules) {
      if (schedules) {
        const schs = schedules.split(')|(')
        if (schs.length) {
          const schedules = []
          for (let s = 0; s < schs.length; s++) {
            const sch = schs[s].split('#|#')
            schedules.push({
              id: sch[0],
              afl_signedby_id: parseInt(sch[1]) || 0,
              sch_status: sch[1],
              afl_validatedby_id: parseInt(sch[2]) || 0,
              fleet_reg: sch[3],
              pilot_name: sch[4],
              copilot_name: sch[5],
              etd_local_time: sch[6],
              eta_local_time: sch[7],
              afl_takeoff: sch[8],
              afl_landing: sch[9],
              afl_taxi_decimal: sch[10],
              dep: sch[11],
              arr: sch[12],
              dep_fullname: sch[13],
              arr_fullname: sch[14],
              notes: sch[15] || ''
            })
          }
          return schedules
        }
      }
      return []
    },
    nexLoaded () {
      if (this.$store.state.logbookHistory.data.length) {
        return this.$store.state.logbookHistory.data.length < parseInt(this.$store.state.logbookHistory.total)
      } else {
        return false
      }
    },
    loadLogbookHistory (newSchedule) {
      this.flighttimeTotal = 0
      const dateFromTo = this.GetDatesFromTo()
      const withAirport = this.selectedAirport ? ('&airport=' + this.selectedAirport) : ''
      this.loadingData = true
      if (this.nexLoaded() || parseInt(this.page) === 0) {
        this.page = (parseInt(this.page) || 0) + 1
      }
      this.$store.dispatch('LOGBOOK_GET', 'page=' + (this.page || 1) + '&limit=30' + (dateFromTo || '') + (withAirport || '') + (newSchedule ? '&new=1' : '')).then((res) => {
        if (res.status) {
          this.flighttimeTotal = parseFloat(parseFloat(res.data.data.total_flighttime).toFixed(1)) || 0
        }
        this.loadingData = false
      })
    },
    GetDatesFromTo () {
      const fromTo = this.dates
      if (fromTo) {
        if (fromTo.length === 2) {
          if ((new Date(fromTo[0])).getTime() < (new Date(fromTo[1])).getTime()) {
            return '&from=' + fromTo[0] + '&to=' + fromTo[1]
          } else {
            return '&from=' + fromTo[1] + '&to=' + fromTo[0]
          }
        }
      }
      return ''
    },
    getRoutes (duty) {
      if (duty.routes) {
        return '<span class="white--text">' + duty.routes + '</span>'
      } else if (duty.routes_obj) {
        const parseRoutes = this.$parseRoutes(duty.routes_obj)
        if (parseRoutes) {
          let routesString = ''
          parseRoutes.map((r, index) => {
            routesString += `
              <div class="route-item">
                <div class="route">
                  ${r.from.shortname}<span>${this.$localDT(r.etd, 'time')}</span>
                </div> → <div class="route">
                  ${r.to.shortname}<span>${this.$localDT(r.eta, 'time')}</span>
                </div>
                <span class="airswitch">
                  ${parseFloat(parseFloat(r.airswitch) + parseFloat(r.perleg)).toFixed(1)}
                </span>
                <div class="d-flex fill-width justify-space-between pt-2 px-1">
                  <small class="font-weight-bold">${(r.aircraft_name || '-')}</small>
                  <small>${r.crew2name}</small>
                </div>
                <div class="px-1 pt-1">
                  <i aria-hidden="true" class="v-icon notranslate mr-1 mdi mdi-information-outline theme--light grey--text text--darken-2" style="font-size: 16px;"></i>
                  ${r.notes || '-'}
                </div>
              </div>
            `
          })
          return routesString
        } else {
          return 'undefined'
        }
      } else {
        return 'no routes'
      }
    },
    GetFleet (id) {
      const fleet = this.$store.state.logbook.aircrafts.find(r => r.id === id)
      if (fleet) {
        return fleet.fleet_reg
      } else {
        return '-'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/global-vars.scss';
@import '../assets/scss/fonts';

.container-history{
  height: 100vh;
  min-height: 100vh;
}

.route-etd-eta {
  position: relative;
  padding-top: 5px;
  font-family: $font-family;

  >.route-item {
    position: relative;
    padding: 8px;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, .8);
    margin-bottom: 6px;

    >div {
      &.route {
        display: inline-block;
        padding: 2px 2px 2px 7px;
        line-height: 100%;
        background: $primary;
        border-radius: 10px;
        color: $white;
        border: solid 1px rgb(241, 198, 2);
        font-size: 10px;

        >span {
          display: inline-block;
          padding: 2px 7px;
          background: #f0f0f0;
          color: $primary;
          border-radius: 10px;
          font-size: 10px;
          margin-left: 5px;
        }
      }
    }

    .airswitch {
      float: right;
      color: $danger;
      padding: 2px;
      border-radius: 2px;
      margin-top: 2px;
      margin-left: 4px;
    }
  }
}

.avatar-top {
  &.v-list-item__avatar {
    align-self: start !important;
  }
}

.logbook-filter {
  top: 0;
  left: 0px;
  width: 100%;
  height: 7rem;
  padding-top: 30px;
  padding-left: 25px;
  padding-right: 25px;
}

.switch-logbook {
  position: fixed;
  bottom: 90px;
  left: 0px;
  width: 100%;
  z-index: 20;
}

.history {
  padding-top: 25px;
  padding-top: 25px;
}

.title {
  @include font-size(16px);
}

.divider {
  width: 350px;
  height: 1px;
  background-image: linear-gradient(to right, $white 0%, $white 50%, transparent 30%);
  background-size: 12px 1.5px;
  background-repeat: repeat-x;
}

.divider2nd {
  display: flex;
  align-items: center;
  color: $white;
}

.dot {
  width: 10px;
  height: 10px;
  border: 2px $white solid;
  border-radius: 60%;
}

.line {
  height: 1px;
  width: 40px;
  background-color: $white;
}

.filled {
  background-color: $white;
}
.container {
  padding: 0;
}

.content-container {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.card-container {
  position: relative;
  min-height: calc(100vh - 112px);
  background-color: $white;
  padding: 30px 30px 0px 30px;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  .content-container{
    padding-bottom: 50px;
    .logbook-empty{
      position: fixed;
      left: 54px;
      top: 50%;
      right: 54px;
      transform: translateY(-50%);
      text-align: center;
    }
  }
}
</style>

<style lang="scss">
@import '../assets/scss/global-vars';
@import '../assets/scss/fonts';

.datepicker-logbook,
.select-input-logbook{
  &.search-history{
    .v-input__slot{
      border-radius: inherit !important;
      padding: 0 16px !important;
    }
    .v-input__prepend-inner{
      margin-top: 0;
      align-self: center;
      .v-icon{
        &::before{
          @include font-size(22px);
        }
      }
    }
    .v-text-field__slot,
    .v-select__slot{
      input{
        @include font-size(13px);
      }
      .v-input__append-inner{
        .v-icon::before{
          color: $danger;
          @include font-size(22px);
        }
        &:last-of-type{
          display: none;
        }
      }
    }
  }
}
.v-expansion-panel-content__wrap {
  padding: 6px 0 0 !important;
  font-family: $font-family;
}

.v-expansion-panel-header {
  padding: 0;
  min-height: 35px;
}

.v-expansion-panel{
  &.v-expansion-panel--active{
    .mdi-chevron-down{
      transform: rotate(180deg);
      transition: .3s ease;
    }
  }
}

.v-expansion-panel--active>.v-expansion-panel-header {
  padding: 0;
  min-height: 50px;
}

.load-button:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color: $danger !important;
  text-transform: capitalize;
  color: $white;
  min-width: 125px;
  min-height: 35px;
}
.v-expansion-panels {
  z-index: 0;
}
.logbook-custom-panel{
  &::before{
    box-shadow: none !important;
  }
}
</style>
